<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'crm-utenti-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Lista Utenti
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
        <b-spinner
            variant="primary"
            style="width: 3rem; height: 3rem;"
            class="mr-1"
            label="loading"
        />
    </div>

    <div v-if="Caricato">
        <b-card>
            <b-card-header class="pt-0">
                <b-card-title>
                  {{ campiform.user.name+' '+campiform.user.surname }}
                  <b-badge variant="light-success" class="ml-1" v-if="campiform.user.status == 'active'">Utente con User Attivo</b-badge>
                  <b-badge variant="light-danger" class="ml-1" v-if="campiform.user.status != 'active'">Utente con User Disattivo</b-badge>
                </b-card-title>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="primary"
                    class="btn-icon rounded-circle"
                    id="`sidebar-azioni`"
                    @click="sidebarActionShow(campiform.id, campiform.user.id, campiform.user.name+' '+campiform.user.surname)"
                >
                    <feather-icon icon="ArrowRightIcon" />
                </b-button>
            </b-card-header>

            <b-card-body class="px-0 py-0">
              <b-tabs pills>
                <b-tab active>
                  <template #title>
                      <feather-icon
                      icon="UserIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Dettagli</span>
                  </template>
                  <b-card bg-variant="Default">
                    <b-row>
                      <b-col md="4" lg="3">
                        <showdata field_name="Nome" :field_value="campiform.user.name" />
                      </b-col>
                      <b-col md="4" lg="3">
                        <showdata field_name="Cognome" :field_value="campiform.user.surname" />
                      </b-col>
                      <b-col md="4" lg="6">
                        <showdata field_name="Email" :field_value="campiform.user.email" />
                      </b-col>
                      <b-col md="4" lg="3">
                        <showdata field_name="Telefono personale" :field_value="campiform.user.phone" />
                      </b-col>
                      <b-col md="4" lg="3">
                        <showdata field_name="Cellulare" :field_value="campiform.user.mobile_phone" />
                      </b-col>
                      <b-col md="4" lg="3">
                        <showdata field_name="Sesso" :field_value="campiform.user.sesso_name" />
                      </b-col>
                      <b-col md="4" lg="3">
                        <showdata field_name="Ruolo" :field_value="campiform.user.role" />
                      </b-col>
                    </b-row>
                  </b-card>
                </b-tab>

                <b-tab>
                  <template #title>
                      <feather-icon
                      icon="MailIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Firma email</span>
                  </template>
                  <b-card bg-variant="Default">
                    <div class="bordo-email" v-html="this.firmaemail.firma"></div>
                  </b-card>
                </b-tab>

              </b-tabs>

            </b-card-body>
        </b-card>
    </div>

    <b-sidebar
      ref="sidebar_azioni"
      id="sidebar-right"
      bg-variant="white"
      :title="infoSideBar.titolo"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>
          
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="$router.push({ name: 'crm-utenti-edit', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="Edit2Icon" /> Modifica
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger" block
            class="mb-1"
            @click="cancellaRiga(infoSideBar.id)"
          >
            <feather-icon icon="Trash2Icon" /> Cancella
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            @click="$router.push({ name: 'crm-utenti-changepassword', params: { id_riga: infoSideBar.user_id, name: infoSideBar.titolo }})"
          >
            <feather-icon icon="KeyIcon" /> Cambio Password
          </b-button>
        </div>

      </div>
    </b-sidebar>
      
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import router from '@/router'
import { BTabs, BTab, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BButton, BFormGroup, BForm, BInputGroup, BFormInput, BSidebar, VBToggle, BBadge, } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, length
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner, 
    BButton,
    BForm,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BSidebar, 
    VBToggle, 
    BBadge,

    showdata,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent, 
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data() {
    return {
      campiform: {
        iban: '',
        bank: '',
        bic: '',
        fixed_monthly: '',
        sale_commissions_new: '',
        sale_commissions_old: '',
        sale_token_new: '',
        sale_token_old: '',
        cash_id: '',
        withholding_tax_id: '',
        user_id: '',
        user: {
          name: '',
          surname: '',
          email: '', 
          password: '', 
          phone: '', 
          mobile_phone: '',
          gender_type_id: '',
          role_id: '',
          sesso_name: ''
        },
      },
      options_gender: [ ],
      Caricato: false,
      errore_caricamento: false,
      infoSideBar: {
        id: '',
        user_id: '',
        fullName: '',
      },

      required, 
      length,
      firmaemail: {
        firma: '',
        user_id: '',
      },
      submitted: false,
      action_press: false,
      url_api_salvataggio_firma_email: '',
      
    }
  },
  created() {
    const genderPromise = this.$http.get('api/fs/gender/list')
    const infoUserPromise = this.$http.get('api/crm/employee/card/'+router.currentRoute.params.id_riga)

    Promise.all([genderPromise, infoUserPromise]).then(response => {
      if(response[0].data.statusCode===200){
        //caricamento lista gender
        this.options_gender = response[0].data.reply
        
        if(response[1].data.statusCode===200){
          //dati user (partendo dalla tabella Employee)
          this.campiform = response[1].data.reply.employeeData

          //per auto-associare la firma all'User_id corretto (ricevuto dall'api che parte dei dati dell'Employee)
          this.firmaemail.user_id = response[1].data.reply.employeeData.user_id

          //sesso
          var id_sesso = this.campiform.user.gender_type_id
          var sesso = this.options_gender.filter(function(elem){
              if(elem.value == id_sesso){
                return elem.text
              }
          })
          this.campiform.user.sesso_name = sesso[0].text

            this.$http.get('api/crm/emailsignature/emailuser/'+response[1].data.reply.employeeData.user_id).then(emailsignature => {
              if(emailsignature.data.statusCode===200){
                console.log(emailsignature.data.reply.data)

                if(emailsignature.data.reply.data === null){
                  //firma non presente -> va fatto il primo inserimento
                  this.url_api_salvataggio_firma_email = 'api/crm/emailsignature/card' //new in salvataggio
                } else {
                  //firma trovata
                  this.firmaemail.firma = emailsignature.data.reply.data.firma
                  this.firmaemail.id = emailsignature.data.reply.data.id

                  this.url_api_salvataggio_firma_email = 'api/crm/emailsignature/card/'+this.firmaemail.id //update in salvataggio
                }
              } else {
                this.$swal({
                  icon: 'warning',
                  title: 'Errore USF 01',
                  text: 'Non è stato possibile recuperare i dati della firma, prego assisare il nostro reparto tecnico',
                  customClass: {
                    confirmButton: 'btn btn-outline-primary',
                  },
                })
              }
            }).catch(e => {
              console.log(e);
            });

          //fine
          this.Caricato = true;
          this.errore_caricamento = false;

        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      } else {
        this.Caricato = false;
        this.errore_caricamento = true;
      }
    })

    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })
    
  },
  methods: {
    validationForm() {
      //alert('inizio processio di salvataggio ... da fare\n\n'+this.url_api_salvataggio_firma_email);
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          console.log('inizio processio di salvataggio ... da fare\n\n'+this.url_api_salvataggio_firma_email);
          console.log(this.firmaemail);

          this.action_press = true
          
          this.$http.post(this.url_api_salvataggio_firma_email, 
            this.firmaemail
          ).then(response => { 
            console.log("risposta server --------")
            console.log(response.data)

            if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
              //risposta positiva
              this.$swal({
                icon: 'success',
                title: response.data.reply.reply,
                confirmButtonText: 'chiudi',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })

              this.action_press = false

            } else {
              //risposta negativa (errore sul server)
              this.$swal({
                icon: 'warning',
                title: 'Si è verificato un errore',
                text: 'La firma non è stata salvata correttamente',
                customClass: {
                  confirmButton: 'btn btn-outline-primary',
                },
              })

              this.action_press = false
            }
          }).catch(e => {
            console.log(e);
          });

        }
      })
    },
    sidebarActionShow(id, user_id, titolo) {
      this.infoSideBar = { id: id, user_id: user_id, titolo: titolo };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    cancellaRiga(id_riga){
      console.log("click cancellaRiga ...... id_riga -> "+id_riga);

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione dell'utente",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/employee/delete/'+id_riga)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)

                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$router.replace('/crm/utenti')
                  .then(() => {
                    this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                    })
                  }) 
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.bordo-email{
  border: 1px solid #eee;
  border-radius: 10px;
  padding: 15px;
}
.bordo-email p{
  margin-bottom: 0;
}
</style>
